import { slice } from "./slice/slice";

export const session = {
  name: slice.name,
  slice: slice,
  actions: {
    ...slice.actions,
  },
  selectors: {},
};
