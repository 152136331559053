import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, ENTITY_NAME } from "../config";
import { loadAuthToken } from "../../auth/utils/auth.utils";

const transformInput = (data: any) => {
  const { scenarioExecutions, notificationTemplateId, ...transformed } = data;
  transformed.scenarioExecutions = scenarioExecutions.map((e: any) => {
    return "/api/scenario_executions/" + e;
  });

  return transformed;
};

const transformOutput = (data: any) => {
  return data;
  // const { notificationTemplate, ...transformed } = data;
  // transformed.notificationTemplateId = notificationTemplate.replace(
  //   "/api/notificationTemplates/",
  //   ""
  // );
  // return transformed;
};

export const thunks = {
  post: createAsyncThunk(
    ENTITY_NAME + "/post",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { data } = arg;
        const transformed = transformInput(data);
        const response = await axios.post(BASE_URL, transformed, {
          headers: {
            "content-type": "application/ld+json",
            Authorization: `Bearer ${loadAuthToken()}`,
          },
        });
        if (response.status === 201) {
          return transformOutput(response.data);
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
};
