import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ENTITY_NAME } from "../config";
import { loadAuthToken } from "../../auth/utils/auth.utils";
import { store } from "../../../app/store";

const url = () => {
  const selectedUnitId = store.getState().session?.selectedUnit?.id || "x";
  return (
    process.env.REACT_APP_ENDPOINT_SERVER +
    "/api/units/" +
    selectedUnitId +
    "/scenarios"
  );
};

export const thunks = {
  getAll: createAsyncThunk(
    ENTITY_NAME + "/getAll",
    async (arg: any, thunkAPI) => {
      //@ts-ignore
      //const selectedUnitId = store.getState().session?.selectedUnit?.id || "x";
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await axios.get(url(), {
          headers: {
            "content-type": "application/ld+json",
            Authorization: `Bearer ${loadAuthToken()}`,
          },
        });
        if (response.status === 200) {
          return response.data;
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
};
