import { entityAdapter } from "../slice/slice";
import { RootState } from "../../../app/store";
import { ENTITY_NAME } from "../config";

const selectors = entityAdapter.getSelectors(
  (state: RootState) => state[ENTITY_NAME].entities
);

export const selectAll = (state: any) => {
  return selectors.selectAll(state);
};

export const selectById = (state: any, args: { id: string }) => {
  return selectors.selectById(state, args.id);
};

export const selectTotal = (state: any) => {
  return selectors.selectTotal(state);
};
