import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  selectReportRowsAllSorted,
  selectReportRowsStatus,
  selectSelected,
} from "../../selectors/report.selector";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { formatDatetimeFromISO } from "../../../../utils/datetime.util";
import { green, orange, red } from "@mui/material/colors";
import React from "react";
import { Search } from "@mui/icons-material";
import { reportSlice } from "../../slices/report.slice";
import ScenarioExecution from "../../../scenarioExecution/components/ScenarioExecution";

interface CellActionsProps {
  scenarioId: string;
  scenarioExecutionId: string;
}
const CellActions = (props: CellActionsProps) => {
  const { scenarioId, scenarioExecutionId } = props;

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(
      reportSlice.actions.setSelectedOpen({
        open: true,
        scenarioId,
        scenarioExecutionId,
      })
    );
  };

  return (
    <IconButton
      onClick={() => {
        handleClick();
      }}
    >
      <Search />
    </IconButton>
  );
};

const reportStatus2Label = {
  filled: "Vyplněno",
  inProgress: "Rozpracováno",
  notFilled: "Není vyplněno",
  canBeFilled: "Není vyplněno",
};
const reportStatus2Color = {
  filled: green[600],
  inProgress: orange[600],
  notFilled: red[600],
  canBeFilled: red[600],
};

const columns: GridColDef[] = [
  {
    field: "unit",
    headerName: "Středisko",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row["store_number"];
    },
  },
  {
    field: "scenario",
    headerName: "Formulář",
    flex: 3,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row["code"] + ": " + params.row["title"];
    },
  },
  {
    field: "valid_from",
    headerName: "Vyplnit od",
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatDatetimeFromISO(params.value);
    },
  },
  {
    field: "valid_to",
    headerName: "Vyplnit do",
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatDatetimeFromISO(params.value);
    },
  },
  {
    field: "reportResult",
    headerName: "Stav",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Box
          sx={{
            fontSize: "90%",
            fontWeight: 400,
            color: "white",
            borderRadius: 3,
            px: 1,
            pt: 0.5,
            pb: 0.5,
            background: reportStatus2Color[params.row["reportResult"]],
          }}
        >
          {reportStatus2Label[params.row["reportResult"]]}
        </Box>
      );
    },
  },
  {
    field: "actions",
    type: "actions",
    headerName: "",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <CellActions
          scenarioId={params.row.scenarioId}
          scenarioExecutionId={params.row.scenarioExecutionId}
        />
      );
    },
  },
];

const ReportRowDialog = () => {
  const selected = useAppSelector(selectSelected);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(
      reportSlice.actions.setSelectedOpen({
        open: false,
        scenarioId: null,
        scenarioExecutionId: null,
      })
    );
  };

  return (
    <Dialog
      open={selected.open}
      onClose={() => handleClose()}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <DialogContent sx={{ py: 4 }}>
        {selected.status === "LOADING" && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress color={"primary"} />
          </Box>
        )}
        {selected.status === "SUCCESS" &&
          !!selected.scenario &&
          !!selected.scenarioExecution && (
            <ScenarioExecution
              scenario={selected.scenario}
              scenarioExecution={selected.scenarioExecution}
            />
          )}
      </DialogContent>
    </Dialog>
  );
};

const ReportRows = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectReportRowsStatus);
  const rows = useAppSelector(selectReportRowsAllSorted);

  const handleClick = (scenarioId, scenarioExecutionId) => {
    dispatch(
      reportSlice.actions.setSelectedOpen({
        open: true,
        scenarioId,
        scenarioExecutionId,
      })
    );
  };

  return (
    <>
      <ReportRowDialog />
      <Box sx={{ mt: 4, minHeight: 400 }}>
        {status === "LOADING" && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress color={"primary"} />
          </Box>
        )}
        {status === "SUCCESS" && (
          <DataGrid
            onRowClick={(params: GridRowParams) => {
              handleClick(
                params.row.scenarioId,
                params.row.scenarioExecutionId
              );
            }}
            hideFooterSelectedRowCount={true}
            rows={rows}
            columns={columns}
            autoHeight={true}
          />
        )}
      </Box>
    </>
  );
};

export default ReportRows;
