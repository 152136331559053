import LayoutSecured from "../features/common/components/LayoutSecured/LayoutSecured";
import Report from "../features/report/components/Report/Report";

const ReportView = () => {
  return (
    <LayoutSecured>
      <Report />
    </LayoutSecured>
  );
};

export default ReportView;
