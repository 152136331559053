import LayoutSecured from "../features/common/components/LayoutSecured/LayoutSecured";
import Session from "../features/session/components/Session";

const DefaultView = () => {
  return (
    <LayoutSecured>
      <Session />
    </LayoutSecured>
  );
};

export default DefaultView;
