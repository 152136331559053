import { Middleware } from "@reduxjs/toolkit";
import { slice } from "../slice/slice";
import { scenarioExecution } from "../../scenarioExecution";
import { appSlice } from "../../app/slices/app.slice";
import { saveSelectedUnit } from "../utils/selectedUnit.localStorage.util";

const sessionMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type === slice.actions.setDateTime.type) {
    store.dispatch(appSlice.actions.setLoading(true));
  }

  if (action.type === scenarioExecution.actions.getAll.fulfilled.type) {
    store.dispatch(appSlice.actions.setLoading(false));
  }

  if (action.type === slice.actions.setSelectedUnit.type) {
    saveSelectedUnit(action.payload);
  }

  return next(action);
};

export default sessionMiddleware;
