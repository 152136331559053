import { createListenerMiddleware } from "@reduxjs/toolkit";
import { reportSlice } from "../slices/report.slice";
import { reportGet, scenarioExecutionGet, scenarioGet } from "../../../api/api";
import { selectReportRowsConfiguration } from "../selectors/report.selector";
import { scenarioExecution } from "../../scenarioExecution";

const reportMiddleware = createListenerMiddleware();
reportMiddleware.startListening({
  type: reportSlice.actions.loadRows.type,
  effect: (action: any, api) => {
    const { withLoading = true } = action.payload;
    const { dispatch, getState } = api;
    const configuration = selectReportRowsConfiguration(getState());
    if (withLoading) {
      dispatch(reportSlice.actions.setRowsStatus("LOADING"));
    }
    reportGet({
      query: configuration,
    })
      .then((response) => {
        if (response.status === 200) {
          // @ts-ignore
          const { data } = response;
          dispatch(reportSlice.actions.setRowsEntitiesSetAll(data.rows));
          dispatch(reportSlice.actions.setRowsStatus("SUCCESS"));
        } else {
          dispatch(reportSlice.actions.setRowsStatus("ERROR"));
        }
      })
      .catch((e) => {
        dispatch(reportSlice.actions.setRowsStatus("ERROR"));
      });
  },
});

reportMiddleware.startListening({
  type: reportSlice.actions.setSelectedOpen.type,
  effect: (action: any, api) => {
    const { dispatch, getState } = api;
    if (action.payload.open) {
      dispatch(reportSlice.actions.setSelectedStatus("LOADING"));
      scenarioExecutionGet({
        query: { id: action.payload.scenarioExecutionId },
      })
        .then((response) => {
          if (response.status === 200) {
            scenarioGet({ query: { id: action.payload.scenarioId } })
              .then((response2) => {
                if (response2.status === 200) {
                  dispatch(
                    reportSlice.actions.setSelected({
                      scenario: response2["data"],
                      scenarioExecution: response["data"],
                    })
                  );
                  dispatch(reportSlice.actions.setSelectedStatus("SUCCESS"));
                } else {
                  dispatch(reportSlice.actions.setSelectedStatus("ERROR"));
                }
              })
              .catch((e) => {
                dispatch(reportSlice.actions.setSelectedStatus("ERROR"));
              });
          } else {
            dispatch(reportSlice.actions.setSelectedStatus("ERROR"));
          }
        })
        .catch((e) => {
          dispatch(reportSlice.actions.setSelectedStatus("ERROR"));
        });
    } else {
      dispatch(reportSlice.actions.loadRows({ withLoading: false }));
      dispatch(reportSlice.actions.setSelectedStatus("IDLE"));
    }
  },
});

export default reportMiddleware;
