import React from "react";
import { Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { addDays, format, subDays } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectSession } from "../../selectors/session.selector";
import { slice } from "../../slice/slice";
import { cs } from "date-fns/locale";

const SessionDateTime: React.FC = () => {
  const dispatch = useAppDispatch();

  const session = useAppSelector(selectSession);
  const dateTime = new Date(session?.dateTime || "now");

  const handlePrevDay = () => {
    dispatch(slice.actions.setDateTime(subDays(dateTime, 1).toISOString()));
  };

  const handleNextDay = () => {
    dispatch(slice.actions.setDateTime(addDays(dateTime, 1).toISOString()));
  };

  return (
    <Box display="flex" alignItems="center">
      <IconButton onClick={handlePrevDay}>
        <ChevronLeft />
      </IconButton>
      <Box sx={{ width: 200, textAlign: "center" }}>
        {format(dateTime, "eeee d. MMMM yyyy", {
          locale: cs,
        })}
      </Box>
      <IconButton onClick={handleNextDay}>
        <ChevronRight />
      </IconButton>
    </Box>
  );
};

export default SessionDateTime;
