import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { ScenarioType } from "../../../types/ScenarioType.type";
import { ScenarioExecutionType } from "../../../types/ScenarioExecutionType.type";

type ReportRowType = any;

export const entityAdapter = createEntityAdapter<ReportRowType>();

export type StateType = {
  rows: {
    configuration: {
      unitIds: string[];
      scenarioIds: string[];
      dateFrom: string | null;
      dateTo: string | null;
      year: number | null;
      month: number | null;
    };
    status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR";
    entities: EntityState<ReportRowType>;
  };
  selected: {
    open: boolean;
    status: "IDLE" | "LOADING" | "SUCCESS" | "ERROR";
    scenarioId: string | null;
    scenarioExecutionId: string | null;
    scenario: ScenarioType | null;
    scenarioExecution: ScenarioExecutionType | null;
  };
  dialog: {
    open: boolean;
    configurationForm: {
      unitId: string | null;
      scenarioId: string | null;
      month: number | null;
      year: number | null;
    };
  };
};

const initialState: StateType = {
  rows: {
    configuration: {
      unitIds: [],
      scenarioIds: [],
      dateFrom: null,
      dateTo: null,
      year: null,
      month: null,
    },
    status: "IDLE",
    entities: entityAdapter.getInitialState(),
  },
  selected: {
    open: false,
    status: "IDLE",
    scenarioId: null,
    scenarioExecutionId: null,
    scenario: null,
    scenarioExecution: null,
  },
  dialog: {
    open: false,
    configurationForm: {
      unitId: null,
      scenarioId: null,
      month: null,
      year: null,
    },
  },
};

export const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    setRowsConfiguration: (state, action) => {
      state.rows.configuration = action.payload;
    },
    loadRows: (state, action) => {},
    setRowsStatus: (state, action) => {
      state.rows.status = action.payload;
    },
    setRowsEntitiesSetAll: (state, action) => {
      state.rows.entities = entityAdapter.setAll(
        state.rows.entities,
        action.payload
      );
    },
    setSelectedOpen: (state, action) => {
      state.selected.open = action.payload.open;
      state.selected.scenarioId = action.payload.scenarioId;
      state.selected.scenarioExecutionId = action.payload.scenarioExecutionId;
    },
    setSelectedStatus: (state, action) => {
      state.selected.status = action.payload;
    },
    setSelected: (state, action) => {
      state.selected.scenario = action.payload.scenario;
      state.selected.scenarioExecution = action.payload.scenarioExecution;
    },
    setDialogOpen: (state, action) => {
      state.dialog.open = action.payload;
    },
    setDialogConfigurationForm: (state, action) => {
      state.dialog.configurationForm = action.payload;
    },
  },
  extraReducers: (builder) => {},
});
