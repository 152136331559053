import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { ArrowCircleRight, CheckCircle, UploadFile } from "@mui/icons-material";
import {
  INPUT_WIDTH,
  NODES_HIDDEN,
  NODES_INPUT,
  STEP_STATUS,
} from "./utils/constants.util";

interface Props {
  stepStatus: string;
  node: any;
  executionValue: any;
  handleUpdate: any;
  handleSubmit: any;
}

const ScenarioExecutionStep = (props: Props) => {
  const { stepStatus, node, executionValue, handleUpdate, handleSubmit } =
    props;

  const [open, setOpen] = useState(false);

  const canContinue = () => {
    if (NODES_INPUT.includes(node.nodeTypeId)) {
      return stepStatus === STEP_STATUS.ENABLED && !!executionValue;
    }

    return stepStatus === STEP_STATUS.ENABLED;
  };

  const handleFileUpload = (e: any) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;

    handleUpdate(name);
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Collapse in={open}>
      <Box
        sx={{
          display: NODES_HIDDEN.includes(node.nodeTypeId) ? "none" : "flex",
          alignItems: "center",
          px: 2,
          py: 1,
          borderTop: "dotted 1px rgba(0, 0, 0, 0.12)",
          transition: "all 250ms",
          color:
            stepStatus === STEP_STATUS.ENABLED
              ? "black"
              : "rgba(0, 0, 0, 0.25)",
          borderBottomLeftRadius: stepStatus === STEP_STATUS.ENABLED ? 8 : 0,
          borderBottomRightRadius: stepStatus === STEP_STATUS.ENABLED ? 8 : 0,
        }}
      >
        <Box sx={{ flexGrow: 1, mr: 1 }}>
          <Typography
            variant={"body1"}
            sx={{ fontWeight: stepStatus === STEP_STATUS.ENABLED ? 700 : 500 }}
          >
            {node.values.displayText}
          </Typography>
        </Box>
        {node.nodeTypeId === "inputText" && (
          <Box sx={{ mr: 1 }}>
            <TextField
              inputProps={{
                sx: {
                  background: "white",
                },
              }}
              disabled={stepStatus === STEP_STATUS.DISABLED}
              sx={{ width: INPUT_WIDTH }}
              size={"small"}
              value={executionValue}
              onChange={(event) => {
                handleUpdate(event.target.value);
              }}
            />
          </Box>
        )}
        {node.nodeTypeId === "inputNumeric" && (
          <Box sx={{ mr: 1 }}>
            <TextField
              type={"number"}
              inputProps={{
                sx: {
                  background: "white",
                },
              }}
              disabled={stepStatus === STEP_STATUS.DISABLED}
              sx={{ width: INPUT_WIDTH }}
              size={"small"}
              value={executionValue}
              onChange={(event) => {
                handleUpdate(event.target.value);
              }}
            />
          </Box>
        )}
        {node.nodeTypeId === "inputFromList" && (
          <Box sx={{ mr: 1 }}>
            <TextField
              select
              inputProps={{
                sx: {
                  background: "white",
                },
              }}
              disabled={stepStatus === STEP_STATUS.DISABLED}
              sx={{ width: INPUT_WIDTH }}
              size={"small"}
              value={executionValue}
              onChange={(event) => {
                handleUpdate(event.target.value);
              }}
            >
              {node.values.options.map((option: any) => (
                <MenuItem key={option} value={option} sx={{ textWrap: "wrap" }}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        {node.nodeTypeId === "inputDate" && (
          <Box sx={{ mr: 1 }}>
            <DatePicker
              renderInput={(inputProps) => (
                <TextField
                  sx={{ width: INPUT_WIDTH }}
                  size={"small"}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  {...inputProps}
                />
              )}
              disabled={stepStatus === STEP_STATUS.DISABLED}
              value={executionValue}
              onChange={(newValue) => handleUpdate(newValue)}
            />
          </Box>
        )}
        {node.nodeTypeId === "inputDateTime" && (
          <Box sx={{ mr: 1 }}>
            <DateTimePicker
              renderInput={(inputProps) => (
                <TextField
                  sx={{ width: INPUT_WIDTH }}
                  size={"small"}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  {...inputProps}
                />
              )}
              disabled={stepStatus === STEP_STATUS.DISABLED}
              value={executionValue}
              onChange={(newValue) => handleUpdate(newValue)}
            />
          </Box>
        )}
        {node.nodeTypeId === "inputUploadImage" && (
          <Box sx={{ mr: 1 }}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<UploadFile />}
              sx={{ marginRight: "1rem" }}
            >
              {!!executionValue ? executionValue : "Nahrát"}
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileUpload}
              />
            </Button>
          </Box>
        )}
        {node.nodeTypeId !== "end" && (
          <Box>
            <IconButton
              disabled={!canContinue()}
              onClick={() => {
                handleSubmit();
              }}
            >
              {stepStatus === STEP_STATUS.DISABLED && <CheckCircle />}
              {stepStatus === STEP_STATUS.ENABLED && (
                <ArrowCircleRight
                  sx={{
                    color: (theme) =>
                      canContinue()
                        ? theme.palette.primary.main
                        : theme.palette.action.disabled,
                  }}
                />
              )}
            </IconButton>
          </Box>
        )}
      </Box>
    </Collapse>
  );
};

export default ScenarioExecutionStep;
