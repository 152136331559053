import { createSelector } from "@reduxjs/toolkit";
import { selectAll } from "../../scenario/selectors/selectors";
import { selectSession } from "../../session/selectors/session.selector";
import { entityAdapter, reportSlice } from "../slices/report.slice";
import { RootState } from "../../../app/store";
import {
  scenarioCodeComparator,
  scenarioCodeComparator2,
} from "../../../utils/scenarioCodeComparator.util";

const selectors = entityAdapter.getSelectors(
  (state: RootState) => state[reportSlice.name].rows.entities
);

export const selectUnitsForReport = createSelector(
  [selectSession],
  (session) => {
    return session.profile?.units || [];
  }
);

export const selectScenariosForReport = createSelector(
  [selectAll],
  (scenarios) => {
    return scenarios;
  }
);

export const selectMonthsForReport = () => {
  const months: number[] = [];
  for (let i = 1; i <= 12; i++) {
    months.push(i);
  }

  return months;
};

export const selectYearsForReport = () => {
  const dt = new Date();
  const currentYear = dt.getFullYear();
  const years: number[] = [];
  for (let i = 0; i <= 10; i++) {
    const year = currentYear - i;
    years.push(year);
  }

  return years;
};

export const selectReportOpen = (state: RootState) => {
  // @ts-ignore
  return state[reportSlice.name as string].dialog.open;
};

export const selectReportConfigurationForm = (state: RootState) => {
  // @ts-ignore
  return state[reportSlice.name as string].dialog.configurationForm;
};

export const selectReportRowsConfiguration = (state: RootState) => {
  return state[reportSlice.name].rows.configuration;
};

export const selectReportRowsStatus = (state: RootState) => {
  return state[reportSlice.name].rows.status;
};

export const selectReportRowsAll = (state: RootState) => {
  return selectors.selectAll(state);
};

export const selectSelected = (state: RootState) => {
  return state[reportSlice.name].selected;
};

export const selectReportRowsAllSorted = createSelector(
  [selectReportRowsAll],
  (rows) => {
    return rows.sort((a, b) => {
      const c0 = a["store_number"].localeCompare(b["store_number"]);
      const c1 = scenarioCodeComparator2(a.code, b.code);
      const c2 = a["valid_from"].localeCompare(b["valid_from"]);

      if (c0 !== 0) {
        return c0;
      }
      if (c1 !== 0) {
        return c1;
      }

      return c2;
    });
  }
);
