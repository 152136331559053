import { AppDispatch } from "../../../app/store";
import { NavigateFunction } from "react-router-dom";
import axios from "axios";
import { slice } from "../../session/slice/slice";
import { loadSelectedUnit } from "../../session/utils/selectedUnit.localStorage.util";

export const loadAuthToken = (): string | null => {
  return localStorage.getItem("authToken");
};

export const saveAuthToken = (value: string) => {
  localStorage.setItem("authToken", value);
};

export const logOut = (dispatch: AppDispatch) => {
  localStorage.removeItem("authToken");
  dispatch(slice.actions.setDateTime(null));
  dispatch(slice.actions.setProfile(null));
  dispatch(slice.actions.setSelectedUnit(null));
};

export const signInSuccess =
  (response: Response, navigate: NavigateFunction) =>
  async (dispatch: AppDispatch) => {
    //@ts-ignore
    const { token, profile } = response.data;

    await saveAuthToken(token);
    fetchProfile(token).then((response: Response) => {
      if (response.status === 200) {
        //@ts-ignore
        const data = response.data;
        const dt = new Date();
        const dateTime = dt.toISOString();
        dispatch(slice.actions.setDateTime(dateTime));
        dispatch(slice.actions.setProfile(data));
        const selectedUnit = loadSelectedUnit();
        dispatch(
          slice.actions.setSelectedUnit(
            !!selectedUnit ? selectedUnit : data.units[0]
          )
        );
      }
    });
  };

export const fetchProfile = (authToken: string): Promise<Response> => {
  return axios.get(process.env.REACT_APP_ENDPOINT_SERVER + "/api/profile", {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};
