import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { logOut } from "../../../auth/utils/auth.utils";
import React, { useState } from "react";
import { selectSession } from "../../../session/selectors/session.selector";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoopIcon from "@mui/icons-material/Loop";
import StoreIcon from "@mui/icons-material/Store";
import {
  Box,
  Divider,
  Hidden,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  Collapse,
  List,
} from "@mui/material";
import { slice } from "../../../session/slice/slice";

const LayoutAppBarProfile = () => {
  const session = useAppSelector(selectSession);
  const profile = session.profile;

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [nestedMenuOpen, setNestedMenuOpen] = useState(false);

  const handleNestedMenuToggle = () => {
    setNestedMenuOpen((prev) => !prev);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setNestedMenuOpen(false);
    setAnchorEl(null);
  };

  const handleChangeUnit = (unit: any) => {
    dispatch(
      slice.actions.setSelectedUnit({
        id: unit.id,
        storeNumber: unit.storeNumber,
      })
    );
    handleClose();
  };

  const handleLogOut = () => {
    logOut(dispatch);
    handleClose();
  };

  const getDisplayName = () => {
    if (!!session && !!profile && profile.units.length === 1) {
      return profile.username;
    }
    if (
      !!session &&
      !!profile &&
      profile.units.length > 1 &&
      !!session.selectedUnit
    ) {
      return profile.username + " / " + session.selectedUnit.storeNumber;
    }
    return "";
  };

  if (!profile) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Hidden smDown>
        <Typography variant="subtitle2" sx={{ ml: 1, mr: 2 }}>
          {getDisplayName()}
        </Typography>
      </Hidden>

      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {profile.units.length > 1 && (
          <>
            <MenuItem onClick={handleNestedMenuToggle}>
              <ListItemIcon>
                <LoopIcon />
              </ListItemIcon>
              <ListItemText primary="Přepnout středisko" />
            </MenuItem>
            <Collapse in={nestedMenuOpen} timeout="auto" unmountOnExit>
              <List disablePadding={true}>
                {[...profile.units]
                  .sort((a, b) => a.storeNumber.localeCompare(b.storeNumber))
                  .map((unit: any) => {
                    return (
                      <MenuItem
                        key={unit.id}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          handleChangeUnit(unit);
                        }}
                      >
                        <ListItemIcon>
                          <StoreIcon />
                        </ListItemIcon>
                        <ListItemText primary={unit.storeNumber} />
                      </MenuItem>
                    );
                  })}
              </List>
            </Collapse>
            <Divider />
          </>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            handleLogOut();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Odhlásit se" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default LayoutAppBarProfile;
