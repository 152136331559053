const localStorageKey = "selectedUnit";

export const saveSelectedUnit = (data: any) => {
  localStorage.setItem(localStorageKey, JSON.stringify(data));
};

export const loadSelectedUnit = () => {
  const data = localStorage.getItem(localStorageKey);
  if (!!data) {
    return JSON.parse(data);
  }

  return null;
};
