import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { load as scenarioLoad } from "../features/scenario/actions/actions";
import { load as scenarioExecutionLoad } from "../features/scenarioExecution/actions/actions";
import { selectSession } from "../features/session/selectors/session.selector";

const LoaderProvider = () => {
  const dispatch = useAppDispatch();
  const session = useAppSelector(selectSession);

  useEffect(() => {
    if (!!session && !!session.selectedUnit) {
      dispatch(scenarioLoad());
      dispatch(
        scenarioExecutionLoad({
          dateTime: session.dateTime,
          unitStoreNumber: session.selectedUnit.storeNumber,
        })
      );
    }
  }, [session]);

  return <></>;
};

export default LoaderProvider;
