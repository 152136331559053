export const getQueryParam = (paramName: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
};

export const removeQueryParamWithoutReloading = (paramName: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(paramName);
  window.history.replaceState({}, document.title, url.toString());
};
