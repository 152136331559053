import { ScenarioType } from "../../../../types/ScenarioType.type";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectSession } from "../../selectors/session.selector";
import { reportSlice } from "../../../report/slices/report.slice";
import { grey } from "@mui/material/colors";

const SessionScenarioReport = (props: { scenario: ScenarioType }) => {
  const { scenario } = props;

  const dispatch = useAppDispatch();
  const session = useAppSelector(selectSession);

  const handleReportOpen = () => {
    const dt = new Date(session.dateTime || "now");
    dispatch(
      reportSlice.actions.setDialogConfigurationForm({
        unitId: session?.selectedUnit?.id || null,
        scenarioId: scenario.id,
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
      })
    );
    dispatch(reportSlice.actions.setDialogOpen(true));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        border: "solid 1px rgba(0, 0, 0, 0.12)",
        background: "#fff",
        cursor: "pointer",
        transition: "all 250ms",
        "&:hover": {
          background: grey[100],
        },
      }}
      onClick={() => {
        handleReportOpen();
      }}
    >
      <Box sx={{ flexGrow: 1 }}>{scenario.title}</Box>
    </Box>
  );
};

export default SessionScenarioReport;
