import { createBrowserRouter } from "react-router-dom";
import DefaultView from "../views/Default.view";
import ReportView from "../views/Report.view";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultView />,
  },
  {
    path: "/report",
    element: <ReportView />,
  },
]);

export default router;
