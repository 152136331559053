import { createSlice } from "@reduxjs/toolkit";

export type StateType = {
  loading: boolean;
  apiError: boolean;
};

const initialState: StateType = {
  loading: false,
  apiError: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setApiError: (state, action) => {
      state.apiError = action.payload;
    },
  },
  extraReducers: (builder) => {},
});
