import React from "react";
import { Helmet } from "react-helmet";
import { RouterProvider } from "react-router-dom";
import router from "./app/router";

const App = () => {
  return (
    <>
      <Helmet>
        <title>LTR Kontrolní listy</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
