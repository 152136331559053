import { useAppSelector } from "../../../../app/hooks";
import { selectApiError } from "../../../app/selectors/app.selector";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const ApiError = () => {
  const apiError = useAppSelector(selectApiError);

  const handleClick = () => {
    window.location.reload();
  };

  return (
    <Dialog open={apiError}>
      <DialogTitle>Objevila se chyba s připojením</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Objevila se chyba s připojením k internetu. Provedené změny nebyly
          uloženy. Je potřeba aktualizovat stránku.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            handleClick();
          }}
        >
          Aktualizovat stránku
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiError;
