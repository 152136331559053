import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, ENTITY_NAME } from "../config";
import { loadAuthToken } from "../../auth/utils/auth.utils";
import { format } from "date-fns";
import { handleError } from "./errorHandlers";

const transformInput = (data: any) => {
  const { unitId, scenarioId, ...transformed } = data;
  transformed.unit = "/api/units/" + unitId;
  transformed.scenario = "/api/scenarios/" + scenarioId;
  return transformed;
};

const transformOutput = (data: any) => {
  const { unit, scenario, ...transformed } = data;
  transformed.unitId = unit.replace("/api/units/", "");
  transformed.scenarioId = scenario.replace("/api/scenarios/", "");
  return transformed;
};

const debounceTimeouts = {};
const debounceDelay = 3000; // Debounce delay v milisekundách

export const thunks = {
  getAll: createAsyncThunk(
    ENTITY_NAME + "/getAll",
    async (arg: any, { rejectWithValue }) => {
      // Získání aktuálního dne, protože se dle něj řídí logika níže
      const now = new Date();

      // Převod vstupního stringu na Date objekt
      const date = new Date(arg.dateTime);

      const nowD = format(now, "yyyy-MM-dd");
      const dateD = format(date, "yyyy-MM-dd");

      // Získání času od kterého se mají scénáře zobrazovat
      const after = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const afterISO = format(after, "yyyy-MM-dd'T'HH:mm:ss") + ".000Z";

      // Získání času do kterého se mají scénáře zobrazovat
      // Pokud daný den na vstupu je stejný jako aktuální den, tak použij hodnoty z daného dne na vstupu
      // Pokud daný den na vstupu je různý než aktuální den, tak použij půlnoc
      const before = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        nowD === dateD ? date.getHours() : 23,
        nowD === dateD ? date.getMinutes() : 59,
        nowD === dateD ? date.getSeconds() : 59,
        999
      );
      const beforeISO = format(before, "yyyy-MM-dd'T'HH:mm:ss") + ".000Z";

      const query =
        "?unit.storeNumber=" +
        arg.unitStoreNumber +
        "&validFrom[before]=" +
        beforeISO +
        "&validTo[after]=" +
        afterISO;
      try {
        const response = await axios.get(BASE_URL + query, {
          headers: {
            "content-type": "application/ld+json",
            Authorization: `Bearer ${loadAuthToken()}`,
          },
        });
        if (response.status === 200) {
          return response.data["hydra:member"].map((r: any) => {
            return transformOutput(r);
          });
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
  getOne: createAsyncThunk(
    ENTITY_NAME + "/getOne",
    async (arg: any, { rejectWithValue }) => {
      const query = "/" + arg.id;
      try {
        const response = await axios.get(BASE_URL + query, {
          headers: {
            "content-type": "application/ld+json",
            Authorization: `Bearer ${loadAuthToken()}`,
          },
        });
        if (response.status === 200) {
          console.log(response.data);
          // return response.data["hydra:member"].map((r: any) => {
          //   return transformOutput(r);
          // });
        } else {
          new Error();
        }
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  ),
  post: createAsyncThunk(
    ENTITY_NAME + "/post",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { data } = arg;
        const transformed = transformInput(data);
        const response = await axios.post(BASE_URL, transformed, {
          headers: {
            "content-type": "application/ld+json",
            Authorization: `Bearer ${loadAuthToken()}`,
          },
        });
        if (response.status === 201) {
          return transformOutput(response.data);
        } else {
          new Error();
        }
      } catch (error: any) {
        handleError(error);
        return rejectWithValue(error.message);
      }
    }
  ),
  put: createAsyncThunk(
    ENTITY_NAME + "/put",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { data } = arg;
        const transformed = transformInput(data);
        const response = await axios.put(
          BASE_URL + "/" + data.id,
          transformed,
          {
            headers: {
              "content-type": "application/ld+json",
              Authorization: `Bearer ${loadAuthToken()}`,
            },
          }
        );
        if (response.status === 200) {
          return transformOutput(response.data);
        } else {
          new Error();
        }
      } catch (error: any) {
        handleError(error);
        return rejectWithValue(error.message);
      }
    }
  ),
  patch: createAsyncThunk(
    ENTITY_NAME + "/patch",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { data } = arg;
        const url = BASE_URL + "/" + data.id;

        if (debounceTimeouts[url]) {
          clearTimeout(debounceTimeouts[url]);
        }

        // Vrátíme Promise, která se vyřeší po zadaném debounceDelay
        return new Promise((resolve, reject) => {
          debounceTimeouts[url] = setTimeout(async () => {
            try {
              const response = await axios.patch(url, data, {
                headers: {
                  "content-type": "application/merge-patch+json",
                  Authorization: `Bearer ${loadAuthToken()}`,
                },
              });
              if (response.status === 200) {
                resolve(transformOutput(response.data));
              } else {
                throw new Error("Unexpected response status");
              }
            } catch (error) {
              handleError(error);
              reject(rejectWithValue(error));
            } finally {
              // Po úspěšném volání smažeme timeout z objektu
              delete debounceTimeouts[url];
            }
          }, debounceDelay);
        });
      } catch (error) {
        handleError(error);
        return rejectWithValue(error);
      }
    }
  ),
  delete: createAsyncThunk(
    ENTITY_NAME + "/delete",
    async (arg: any, { rejectWithValue }) => {
      try {
        const { id } = arg.data;
        const response = await axios.delete(BASE_URL + "/" + id, {
          headers: {
            "content-type": "application/ld+json",
            Authorization: `Bearer ${loadAuthToken()}`,
          },
        });
        if (response.status === 204) {
          return id;
        } else {
          new Error();
        }
      } catch (error: any) {
        handleError(error);
        return rejectWithValue(error.message);
      }
    }
  ),
};
