import { useAppSelector } from "../../../app/hooks";
import { selectAllSorted } from "../../scenarioExecution/selectors/selectors";
import { selectAll as scenarioSelectAll } from "../../scenario/selectors/selectors";
import { ScenarioExecutionType } from "../../../types/ScenarioExecutionType.type";
import { selectById } from "../../scenario/selectors/selectors";
import { Box, CircularProgress, Collapse, Typography } from "@mui/material";
import SessionScenario from "./SessionScenario/SessionScenario";
import SessionDateTime from "./SessionDateTime/SessionDateTime";
import LoaderProvider from "../../../providers/LoaderProvider";
import { selectAppLoading } from "../../app/selectors/app.selector";
import ReportDialog from "../../report/components/ReportDialog/ReportDialog";
import React, { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import smileySrc from "./smiley.png";
import { scenarioCodeComparator } from "../../../utils/scenarioCodeComparator.util";
import SessionScenarioReport from "./SessionScenarioReport/SessionScenarioReport";
import ScenarioExecution from "../../scenarioExecution/components/ScenarioExecution/ScenarioExecution";

const SessionItem = (props: { scenarioExecution: ScenarioExecutionType }) => {
  const { scenarioExecution } = props;
  const scenario = useAppSelector((state) =>
    selectById(state, { id: scenarioExecution.scenarioId as unknown as string })
  );

  if (!scenarioExecution || !scenario) {
    return <></>;
  }

  return (
    <ScenarioExecution
      scenario={scenario}
      scenarioExecution={scenarioExecution}
    />
  );
};

const SessionScenarioExecutions = () => {
  const scenarioExecutions = useAppSelector(selectAllSorted);
  const loading = useAppSelector(selectAppLoading);

  if (loading) {
    return (
      <>
        <Box
          sx={{
            py: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  if (scenarioExecutions.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          background: "#fff",
          border: "solid 1px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box
          component={"img"}
          src={smileySrc}
          sx={{ width: 70, height: 70, mr: 2 }}
        />
        <Box
          sx={{
            fontWeight: 700,
            fontSize: 24,
          }}
        >
          Pro tento den zde není nic k vyplnění&hellip;
        </Box>
      </Box>
    );
  }

  return (
    <>
      {scenarioExecutions.map((scenarioExecution) => {
        return (
          <Box key={scenarioExecution.id} sx={{ mb: 1 }}>
            <SessionItem scenarioExecution={scenarioExecution} />
          </Box>
        );
      })}
    </>
  );
};

const SessionScenarios = () => {
  const scenarios = useAppSelector(scenarioSelectAll);

  return (
    <Box>
      {scenarios
        .filter((s) => !s.withCron)
        .sort(scenarioCodeComparator)
        .map((s) => {
          return (
            <Box key={s.id} sx={{ mb: 1 }}>
              <SessionScenario scenario={s} />
            </Box>
          );
        })}
    </Box>
  );
};

const SessionScenariosReport = () => {
  const scenarios = useAppSelector(scenarioSelectAll);

  return (
    <Box>
      {scenarios.sort(scenarioCodeComparator).map((s) => {
        return (
          <Box key={s.id} sx={{ mb: 1 }}>
            <SessionScenarioReport scenario={s} />
          </Box>
        );
      })}
    </Box>
  );
};

type SessionBlockPropsType = {
  title: string;
  children: React.ReactNode;
  openable: boolean;
  opened: boolean;
};
const SessionBlock = (props: SessionBlockPropsType) => {
  const { title, children, openable = true, opened = false } = props;

  const [open, setOpen] = useState(opened);

  const handleToggleOpen = () => {
    if (openable) {
      setOpen((prev) => !prev);
    }
  };

  return (
    <>
      <Box
        onClick={() => {
          handleToggleOpen();
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: openable ? "pointer" : "default",
          mb: 1,
        }}
      >
        {openable && (
          <ChevronRightIcon
            sx={{
              transition: "transform 500ms",
              transform: open ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
        )}
        <Typography variant={"h6"}>{title}</Typography>
      </Box>
      <Collapse in={open}>
        <Box sx={{ mb: 3 }}>{children}</Box>
      </Collapse>
    </>
  );
};

const Session = () => {
  return (
    <>
      <LoaderProvider />
      <ReportDialog />
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2, mt: 3 }}>
        <SessionDateTime />
      </Box>
      <SessionBlock
        title={"Formuláře k vyplnění"}
        openable={false}
        opened={true}
      >
        <SessionScenarioExecutions />
      </SessionBlock>
      <SessionBlock title={"Ostatní formuláře"} openable={true} opened={false}>
        <SessionScenarios />
      </SessionBlock>
      <SessionBlock
        title={"Reporty a vyplněné formuláře"}
        openable={true}
        opened={false}
      >
        <SessionScenariosReport />
      </SessionBlock>
    </>
  );
};

export default Session;
