import axios from "axios";
import { loadAuthToken } from "../features/auth/utils/auth.utils";

const baseUrl = process.env.REACT_APP_ENDPOINT_SERVER;

const buildHeaders = () => {
  const authToken = loadAuthToken();
  return { Authorization: `Bearer ${authToken}` };
};

const tryLogin = (props: any): Promise<Response> => {
  if (props.params.credentials.hasOwnProperty("token")) {
    return axios.post(baseUrl + "/auth", {
      token: props.params.credentials.token,
    });
  }
  return axios.post(baseUrl + "/auth", {
    username: props.params.credentials.username,
    password: props.params.credentials.password,
  });
};

const scenarioExecutionPost = (props: any): Promise<Response> => {
  const { data } = props;
  const { unitId, scenarioId, ...transformed } = data;
  transformed.unit = "/api/units/" + unitId;
  transformed.scenario = "/api/scenarios/" + scenarioId;
  return axios.post(baseUrl + "/api/scenario_executions", transformed, {
    headers: { ...buildHeaders(), "content-type": "application/ld+json" },
  });
};

const scenarioExecutionReportDownload = (props: any): Promise<Response> => {
  const { scenarioId, unitId, month, year } = props.query;

  const query = [
    ["scenario_id", scenarioId].join("="),
    ["unit_id", unitId].join("="),
    ["month", month].join("="),
    ["year", year].join("="),
  ].join("&");
  const url = baseUrl + "/api/scenario_execution_reports/download?" + query;

  return axios.get(url, {
    responseType: "blob",
    headers: { ...buildHeaders() },
  });
};

const reportGet = (props: any): Promise<Response> => {
  const { dateFrom, dateTo } = props.query;

  const query = [
    ["dateFrom", dateFrom].join("="),
    ["dateTo", dateTo].join("="),
  ].join("&");
  const url = baseUrl + "/api/report?" + query;

  return axios.get(url, {
    headers: { ...buildHeaders() },
  });
};

const scenarioGet = (props: any): Promise<Response> => {
  const url = baseUrl + "/api/scenarios/" + props.query.id;

  return axios.get(url, {
    headers: { ...buildHeaders() },
  });
};

const scenarioExecutionGet = (props: any): Promise<Response> => {
  const url = baseUrl + "/api/scenario_executions/" + props.query.id;

  return axios.get(url, {
    headers: { ...buildHeaders() },
  });
};

export {
  tryLogin,
  scenarioExecutionPost,
  scenarioExecutionReportDownload,
  reportGet,
  scenarioGet,
  scenarioExecutionGet,
};
