import { ScenarioType } from "../../../../types/ScenarioType.type";
import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { buildEntity } from "../../../scenarioExecution/builder/builder";
import { scenarioExecutionPost } from "../../../../api/api";
import { load as scenarioExecutionLoad } from "../../../scenarioExecution/actions/actions";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectSession } from "../../selectors/session.selector";
import React, { useState } from "react";
import { CheckCircle } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";

const SessionScenario = (props: { scenario: ScenarioType }) => {
  const { scenario } = props;

  const dispatch = useAppDispatch();
  const session = useAppSelector(selectSession);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCreateScenarioExecution = (scenario: ScenarioType) => {
    setLoading(true);

    const dt = new Date(session.dateTime || "now");
    const dt2 = new Date(session.dateTime || "now");
    dt.setHours(0, 0, 0, 0);
    dt2.setHours(23, 59, 59, 0);
    const scenarioExecution = buildEntity();
    // @ts-ignore
    scenarioExecution.scenarioId = scenario.id;
    // @ts-ignore
    scenarioExecution.unitId = session.selectedUnit.id;
    scenarioExecution.validFrom = dt.toISOString();
    scenarioExecution.validTo = dt2.toISOString();

    scenarioExecutionPost({ data: scenarioExecution })
      .then((response) => {
        if (response.status === 201 && !!session && !!session.selectedUnit) {
          dispatch(
            scenarioExecutionLoad({
              dateTime: session.dateTime,
              unitStoreNumber: session.selectedUnit.storeNumber,
            })
          );
          setOpen(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          border: "solid 1px rgba(0, 0, 0, 0.12)",
          background: "#fff",
          cursor: "pointer",
          transition: "all 250ms",
          "&:hover": {
            background: grey[100],
          },
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{scenario.title}</Box>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Chcete začít vyplňovat formulář "{scenario.title}"?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            startIcon={<CloseIcon />}
          >
            Ne
          </Button>
          <LoadingButton
            onClick={() => {
              handleCreateScenarioExecution(scenario);
            }}
            color="primary"
            variant="contained"
            disabled={loading}
            loading={loading}
            startIcon={<CheckCircle />}
          >
            Ano
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SessionScenario;
