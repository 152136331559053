import { RootState } from "../../../app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectSession = (state: RootState) => {
  return state.session;
};

export const selectProfile = createSelector([selectSession], (session) => {
  return session.profile;
});
