import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { selectReportRowsConfiguration } from "../../selectors/report.selector";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import React, { useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import { reportSlice } from "../../slices/report.slice";
import ReportRows from "./ReportRows";

const Report = () => {
  const dispatch = useAppDispatch();
  const configuration = useAppSelector(selectReportRowsConfiguration);

  const handleChangeDate = (name, newValue) => {
    if (name === "dateFrom") {
      const dt = new Date(newValue);
      dt.setHours(0, 0, 0, 0);
      dispatch(
        reportSlice.actions.setRowsConfiguration({
          ...configuration,
          [name]: dt.toISOString(),
        })
      );
    }
    if (name === "dateTo") {
      const dt = new Date(newValue);
      dt.setHours(23, 59, 59, 0);
      dispatch(
        reportSlice.actions.setRowsConfiguration({
          ...configuration,
          [name]: dt.toISOString(),
        })
      );
    }
  };

  const handleSubmit = () => {
    if (!!configuration.dateFrom && !!configuration.dateTo) {
      dispatch(reportSlice.actions.loadRows({ withLoading: true }));
    }
  };

  useEffect(() => {
    if (!configuration.dateFrom) {
      const dt = new Date();
      handleChangeDate("dateFrom", dt);
    }
    if (!configuration.dateTo) {
      const dt = new Date();
      handleChangeDate("dateTo", dt);
    }
  }, [configuration]);

  return (
    <>
      <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
        <Box sx={{ mr: 2 }}>
          <DatePicker
            label="Datum od"
            value={configuration.dateFrom}
            onChange={(newValue) => {
              handleChangeDate("dateFrom", newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box sx={{ mr: 2 }}>
          <DatePicker
            label="Datum do"
            value={configuration.dateTo}
            onChange={(newValue) => {
              handleChangeDate("dateTo", newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => {
              handleSubmit();
            }}
          >
            Zobrazit
          </Button>
        </Box>
      </Box>
      <Box>
        <ReportRows />
      </Box>
    </>
  );
};

export default Report;
