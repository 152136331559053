import { v4 as uuidv4 } from "uuid";
import { ENTITY_TYPE } from "../config";

export const buildEntity = (): ENTITY_TYPE => {
  return {
    id: uuidv4(),
    executionCompletedAt: null,
    executionStartedAt: null,
    prescriptionId: null,
    scenarioId: null,
    unitId: null,
    validFrom: "",
    validTo: "",
    progress: undefined,
    sessionId: null,
  };
};
