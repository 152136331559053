export const formatDatetimeFromISO = (dtStringISO) => {
  const b = dtStringISO.split(/\D+/);
  const dt = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5])); //převedení ISO stringu na Date objekt

  const y = dt.getFullYear();
  const m = dt.getMonth() + 1;
  const d = dt.getDate();
  const h = dt.getHours();
  const i = dt.getMinutes();

  return (
    [d < 10 ? "0" + d : d, m < 10 ? "0" + m : m, y].join(".") +
    " " +
    [h < 10 ? "0" + h : h, i < 10 ? "0" + i : i].join(":")
  );
};

export const formatDatetime = (dtString) => {
  const dt = new Date(dtString);

  const y = dt.getFullYear();
  const m = dt.getMonth() + 1;
  const d = dt.getDate();
  const h = dt.getHours();
  const i = dt.getMinutes();

  return (
    [d < 10 ? "0" + d : d, m < 10 ? "0" + m : m, y].join(".") +
    " " +
    [h < 10 ? "0" + h : h, i < 10 ? "0" + i : i].join(":")
  );
};
