import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { session } from "../features/session";
import { scenario } from "../features/scenario";
import { scenarioExecution } from "../features/scenarioExecution";
import sessionMiddleware from "../features/session/middlewares/session.middleware";
import { appSlice } from "../features/app/slices/app.slice";
import { reportSlice } from "../features/report/slices/report.slice";
import reportMiddleware from "../features/report/middlewares/report.middleware";

export const store = configureStore({
  reducer: {
    [appSlice.name]: appSlice.reducer,
    [reportSlice.name]: reportSlice.reducer,
    [scenario.slice.name]: scenario.slice.reducer,
    [session.slice.name]: session.slice.reducer,
    [scenarioExecution.slice.name]: scenarioExecution.slice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(sessionMiddleware)
      .concat(reportMiddleware.middleware);
  },
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
