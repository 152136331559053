import { thunks } from "./thunks/thunks";
import { ENTITY_NAME } from "./config";

export const notification = {
  name: ENTITY_NAME,
  //slice: slice,
  actions: {
    //...slice.actions,
    //getAll: thunks.getAll,
    post: thunks.post,
    //put: thunks.put,
    //patch: thunks.patch,
    //delete: thunks.delete,
  },
  selectors: {
    //selectAll: selectAll,
  },
};
