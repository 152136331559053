import { produce } from "immer";
import { Progress } from "../../../../../types/ScenarioExecutionType.type";
import { se_saveScenarioExecutionState } from "./syncWithDb.util";

export const ACTION = {
  PROGRESS_SET: "PROGRESS_SET",
  PROGRESS_STEP_ADD: "PROGRESS_STEP_ADD",
  PROGRESS_STEP_SET: "PROGRESS_STEP_STEP",
  PROGRESS_LOOP_INDEX_INCREMENT: "PROGRESS_LOOP_INDEX_INCREMENT",
  SCENARIO_EXECUTION_SET: "SCENARIO_EXECUTION_SET",
};

export interface ScenarioExecutionState {
  executionStartedAt?: string | null | undefined;
  executionCompletedAt?: string | null | undefined;
  scenarioExecutionId: string;
  error: string;
  progress: Progress;
}

export const scenarioExecutionReducer = (
  state: ScenarioExecutionState,
  action: any
) => {
  switch (action.type) {
    case ACTION.PROGRESS_SET: {
      return produce(state, (draft: ScenarioExecutionState) => {
        draft.progress = action.payload;
      });
    }
    case ACTION.PROGRESS_STEP_ADD: {
      const { step, scenarioExecution } = action.payload;
      const newState = produce(state, (draft: ScenarioExecutionState) => {
        draft.progress.stepsByIds[step.id] = {
          id: step.id, //stepId
          nodeId: step.nodeId,
          nodeTypeId: step.nodeTypeId,
          customId: step?.values?.customId || step.id,
          value: null,
          submittedAt: null,
        };
        draft.progress.stepIds.push(step.id);
      });
      se_saveScenarioExecutionState(newState);
      return newState;
    }
    case ACTION.PROGRESS_LOOP_INDEX_INCREMENT: {
      const newState = produce(state, (draft: ScenarioExecutionState) => {
        draft.progress.loopIndex = draft.progress.loopIndex + 1;
      });
      se_saveScenarioExecutionState(newState);
      return newState;
    }
    case ACTION.PROGRESS_STEP_SET: {
      const newState = produce(state, (draft: ScenarioExecutionState) => {
        draft.progress.stepsByIds[action.payload.id] = action.payload;
      });
      if (!!action.payload.submittedAt) {
        se_saveScenarioExecutionState(newState);
      }
      return newState;
    }
    default: {
      return state;
    }
  }
};
