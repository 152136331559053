import { ScenarioExecutionState } from "./reducer.util";
import { getScenarioExecutionStatus } from "./function.utils";
import { SCENARIO_EXECUTION_STATUS } from "./constants.util";
import { store } from "../../../../../app/store";
import { notification as n } from "../../../../notification";
import { scenarioExecution as se } from "../../../index";

export const se_handleNotification = (args: {
  scenarioExecutionId: string | null;
  notification: any;
}) => {
  const data = {
    scenarioExecutions: [args.scenarioExecutionId],
    recipient: args.notification.sendTo,
    sendWhen: args.notification.sendWhen,
    status: "idle",
  };
  store.dispatch(n.actions.post({ data }));
};

export const se_saveScenarioExecutionState = (
  scenarioExecutionState: ScenarioExecutionState
) => {
  const data = {
    id: scenarioExecutionState.scenarioExecutionId,
    progress: scenarioExecutionState.progress,
  };
  if (
    scenarioExecutionState.progress.stepIds.length >= 1 &&
    !scenarioExecutionState.executionStartedAt
  ) {
    const dt = new Date();
    // @ts-ignore
    data.executionStartedAt = dt.toISOString();
  }
  if (
    getScenarioExecutionStatus(scenarioExecutionState) ===
    SCENARIO_EXECUTION_STATUS.COMPLETED
  ) {
    const dt = new Date();
    // @ts-ignore
    data.executionCompletedAt = dt.toISOString();
  }
  store.dispatch(se.actions.patch({ data }));
};
