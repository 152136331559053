//@ts-nocheck

import { produce } from "immer";
import { ScenarioType } from "../../../types/ScenarioType.type";
import React, { useEffect, useReducer, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import {
  ArrowCircleRight,
  ArrowDropDown,
  CheckCircle,
  UploadFile,
} from "@mui/icons-material";
import { edgeConditionOperationTypes } from "../../../enums/edgeConditionOperationTypes";
import { ScenarioExecutionType } from "../../../types/ScenarioExecutionType.type";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { scenarioExecution as se } from "../index";
import { store } from "../../../app/store";
import { notification as n } from "../../notification";

// const formatDatetime = (dtStringISO) => {
//   const b = dtStringISO.split(/\D+/);
//   const dt = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6])); //převedení ISO stringu na Date objekt
//
//   const y = dt.getFullYear();
//   const m = dt.getMonth() + 1;
//   const d = dt.getDate();
//   const h = dt.getHours();
//   const i = dt.getMinutes();
//
//   return (
//     [d < 10 ? "0" + d : d, m < 10 ? "0" + m : m, y].join(".") +
//     " " +
//     [h < 10 ? "0" + h : h, i < 10 ? "0" + i : i].join(":")
//   );
// };

export const formatDatetime = (dtString) => {
  const dt = new Date(dtString);

  const y = dt.getFullYear();
  const m = dt.getMonth() + 1;
  const d = dt.getDate();
  const h = dt.getHours();
  const i = dt.getMinutes();

  return (
    [d < 10 ? "0" + d : d, m < 10 ? "0" + m : m, y].join(".") +
    " " +
    [h < 10 ? "0" + h : h, i < 10 ? "0" + i : i].join(":")
  );
};

const INPUT_WIDTH = 230;

const NODES_HIDDEN = ["notification"];

const NODES_INPUT = [
  "inputNumeric",
  "inputFromList",
  "inputUploadImage",
  "inputText",
  "inputDate",
  "inputDateTime",
];

const SCENARIO_MODE = {
  SEQ: "SEQ",
  ALL: "ALL",
};

const SCENARIO_EXECUTION_STATUS = {
  NOT_COMPLETED: "notCompleted",
  IN_PROGRESS: "inProgress",
  COMPLETED: "completed",
};

const STEP_STATUS = {
  ENABLED: "enabled",
  DISABLED: "disabled",
};

const getScenarioExecutionStatusProps = (scenarioExecutionStatus) => {
  switch (scenarioExecutionStatus) {
    case SCENARIO_EXECUTION_STATUS.NOT_COMPLETED: {
      return {
        label: "Není vyplněno",
        background: red[50],
        color: "error",
      };
    }
    case SCENARIO_EXECUTION_STATUS.IN_PROGRESS: {
      return {
        label: "Rozpracováno",
        background: orange[50],
        color: "warning",
      };
    }
    case SCENARIO_EXECUTION_STATUS.COMPLETED: {
      return {
        label: "Vyplněno",
        background: green[50],
        color: "success",
      };
    }
  }
};

const handleNotification = (args: any) => {
  const data = {
    scenarioExecutions: [args.scenarioExecutionId],
    recipient: args.notification.sendTo,
    sendWhen: args.notification.sendWhen,
    status: "idle",
  };
  store.dispatch(n.actions.post({ data }));
};

const saveScenarioExecutionState = (scenarioExecutionState) => {
  const data = {
    id: scenarioExecutionState.scenarioExecutionId,
    progress: scenarioExecutionState.progress,
  };
  if (
    scenarioExecutionState.progress.stepIds.length >= 1 &&
    !scenarioExecutionState.executionStartedAt
  ) {
    const dt = new Date();
    data.executionStartedAt = dt.toISOString();
  }
  if (
    getScenarioExecutionStatus(scenarioExecutionState) ===
    SCENARIO_EXECUTION_STATUS.COMPLETED
  ) {
    const dt = new Date();
    data.executionCompletedAt = dt.toISOString();
  }
  store.dispatch(se.actions.patch({ data }));
};

const ACTION = {
  PROGRESS_SET: "PROGRESS_SET",
  PROGRESS_STEP_ADD: "PROGRESS_STEP_ADD",
  PROGRESS_STEP_SET: "PROGRESS_STEP_STEP",
  SCENARIO_EXECUTION_SET: "SCENARIO_EXECUTION_SET",
};
const getScenarioExecutionStatus = (
  scenarioExecutionState,
  returnDefault = SCENARIO_EXECUTION_STATUS.IN_PROGRESS
) => {
  if (scenarioExecutionState.progress.stepIds.length === 0) {
    return SCENARIO_EXECUTION_STATUS.NOT_COMPLETED;
  }

  if (
    scenarioExecutionState.progress.mode === SCENARIO_MODE.SEQ &&
    scenarioExecutionState.progress.stepIds.filter((stepId) => {
      return (
        scenarioExecutionState.progress.stepsByIds[stepId].nodeTypeId === "end"
      );
    }).length === 1
  ) {
    return SCENARIO_EXECUTION_STATUS.COMPLETED;
  }

  if (
    scenarioExecutionState.progress.mode === SCENARIO_MODE.ALL &&
    scenarioExecutionState.progress.stepIds
      .filter((stepId) => {
        return NODES_INPUT.includes(
          scenarioExecutionState.progress.stepsByIds[stepId].nodeTypeId
        );
      })
      .every((stepId) => {
        return !!scenarioExecutionState.progress.stepsByIds[stepId].submittedAt;
      })
  ) {
    return SCENARIO_EXECUTION_STATUS.COMPLETED;
  }

  return returnDefault;
};
const scenarioExecutionReducer = (state, action) => {
  switch (action.type) {
    case ACTION.PROGRESS_SET: {
      return produce(state, (draft) => {
        draft.progress = action.payload;
      });
    }
    case ACTION.PROGRESS_STEP_ADD: {
      const newState = produce(state, (draft) => {
        draft.progress.stepsByIds[action.payload.id] = {
          id: action.payload.id,
          nodeTypeId: action.payload.nodeTypeId,
          customId: action.payload?.values?.customId || action.payload.id,
          value: null,
          submittedAt: null,
        };
        draft.progress.stepIds.push(action.payload.id);
      });
      saveScenarioExecutionState(newState);
      return newState;
    }
    case ACTION.PROGRESS_STEP_SET: {
      const newState = produce(state, (draft) => {
        draft.progress.stepsByIds[action.payload.id] = action.payload;
      });
      if (!!action.payload.submittedAt) {
        saveScenarioExecutionState(newState);
      }
      return newState;
    }
    default: {
      return state;
    }
  }
};

const ScenarioExecutionHeader = (props: any) => {
  const { title, handleClick, status, validFrom, validTo } = props;

  // @ts-ignore
  const statusProps = getScenarioExecutionStatusProps(status);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        alignItems: "center",
        backgroundColor: statusProps.background,
      }}
      onClick={handleClick}
    >
      <Box sx={{ flexGrow: 2, mr: 2 }}>
        {!!validFrom && !!validTo && (
          <Typography sx={{ fontSize: "75%" }}>
            {formatDatetime(validFrom)} - {formatDatetime(validTo)}
          </Typography>
        )}
        <Typography variant={"h6"}>{title}</Typography>
      </Box>
      <Box sx={{ mr: 1 }}>
        <Chip label={statusProps.label} color={statusProps.color} />
      </Box>
      <Box>
        <IconButton size={"small"}>
          <ArrowDropDown />
        </IconButton>
      </Box>
    </Box>
  );
};

const ScenarioExecutionStep = (props: any) => {
  const { stepStatus, node, executionValue, handleUpdate, handleSubmit } =
    props;

  const [open, setOpen] = useState(false);

  const canContinue = () => {
    if (NODES_INPUT.includes(node.nodeTypeId)) {
      return stepStatus === STEP_STATUS.ENABLED && !!executionValue;
    }

    return stepStatus === STEP_STATUS.ENABLED;
  };

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;

    handleUpdate(name);
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Collapse in={open}>
      <Box
        sx={{
          display: NODES_HIDDEN.includes(node.nodeTypeId) ? "none" : "flex",
          alignItems: "center",
          px: 2,
          py: 1,
          borderTop: "dotted 1px rgba(0, 0, 0, 0.12)",
          transition: "all 250ms",
          color:
            stepStatus === STEP_STATUS.ENABLED
              ? "black"
              : "rgba(0, 0, 0, 0.25)",
          borderBottomLeftRadius: stepStatus === STEP_STATUS.ENABLED ? 8 : 0,
          borderBottomRightRadius: stepStatus === STEP_STATUS.ENABLED ? 8 : 0,
        }}
      >
        <Box sx={{ flexGrow: 1, mr: 1 }}>
          <Typography
            variant={"body1"}
            sx={{ fontWeight: stepStatus === STEP_STATUS.ENABLED ? 700 : 500 }}
          >
            {node.values.displayText}
          </Typography>
        </Box>
        {node.nodeTypeId === "inputText" && (
          <Box sx={{ mr: 1 }}>
            <TextField
              inputProps={{
                sx: {
                  background: "white",
                },
              }}
              disabled={stepStatus === STEP_STATUS.DISABLED}
              sx={{ width: INPUT_WIDTH }}
              size={"small"}
              value={executionValue}
              onChange={(event) => {
                handleUpdate(event.target.value);
              }}
            />
          </Box>
        )}
        {node.nodeTypeId === "inputNumeric" && (
          <Box sx={{ mr: 1 }}>
            <TextField
              type={"number"}
              inputProps={{
                sx: {
                  background: "white",
                },
              }}
              disabled={stepStatus === STEP_STATUS.DISABLED}
              sx={{ width: INPUT_WIDTH }}
              size={"small"}
              value={executionValue}
              onChange={(event) => {
                handleUpdate(event.target.value);
              }}
            />
          </Box>
        )}
        {node.nodeTypeId === "inputFromList" && (
          <Box sx={{ mr: 1 }}>
            <TextField
              select
              inputProps={{
                sx: {
                  background: "white",
                },
              }}
              disabled={stepStatus === STEP_STATUS.DISABLED}
              sx={{ width: INPUT_WIDTH }}
              size={"small"}
              value={executionValue}
              onChange={(event) => {
                handleUpdate(event.target.value);
              }}
            >
              {node.values.options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        {node.nodeTypeId === "inputDate" && (
          <Box sx={{ mr: 1 }}>
            <DatePicker
              renderInput={(inputProps) => (
                <TextField
                  sx={{ width: INPUT_WIDTH }}
                  size={"small"}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  {...inputProps}
                />
              )}
              disabled={stepStatus === STEP_STATUS.DISABLED}
              value={executionValue}
              onChange={(newValue) => handleUpdate(newValue)}
            />
          </Box>
        )}
        {node.nodeTypeId === "inputDateTime" && (
          <Box sx={{ mr: 1 }}>
            <DateTimePicker
              renderInput={(inputProps) => (
                <TextField
                  sx={{ width: INPUT_WIDTH }}
                  size={"small"}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  {...inputProps}
                />
              )}
              disabled={stepStatus === STEP_STATUS.DISABLED}
              value={executionValue}
              onChange={(newValue) => handleUpdate(newValue)}
            />
          </Box>
        )}
        {node.nodeTypeId === "inputUploadImage" && (
          <Box sx={{ mr: 1 }}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<UploadFile />}
              sx={{ marginRight: "1rem" }}
            >
              {!!executionValue ? executionValue : "Nahrát"}
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileUpload}
              />
            </Button>
          </Box>
        )}
        {node.nodeTypeId !== "end" && (
          <Box>
            <IconButton
              disabled={!canContinue()}
              onClick={() => {
                handleSubmit();
              }}
            >
              {stepStatus === STEP_STATUS.DISABLED && <CheckCircle />}
              {stepStatus === STEP_STATUS.ENABLED && (
                <ArrowCircleRight
                  color={canContinue() ? "primary" : "default"}
                />
              )}
            </IconButton>
          </Box>
        )}
      </Box>
    </Collapse>
  );
};

type ScenarioExecutionProps = {
  scenario: ScenarioType;
  scenarioExecution: ScenarioExecutionType | null;
};

const ScenarioExecution = (props: ScenarioExecutionProps) => {
  const { scenario, scenarioExecution } = props;
  const {
    title = "",
    nodes = {},
    edges = {},
    mode = SCENARIO_MODE.SEQ,
  } = scenario;

  const [scenarioExecutionState, dispatch] = useReducer(
    scenarioExecutionReducer,
    {
      scenarioExecutionId: scenarioExecution.id,
      progress:
        !!scenarioExecution.progress &&
        scenarioExecution.progress.hasOwnProperty("stepsByIds")
          ? scenarioExecution.progress
          : {
              stepsByIds: {},
              stepIds: [],
              error: "",
              mode: mode,
            },
    }
  );

  const [open, setOpen] = useState(false);

  const findDestinationIdForNodeId = (nodeId: string, executionValue: any) => {
    const edge = Object.values(edges)
      .sort((a, b) => {
        return (
          edgeConditionOperationTypes[a.values.condition.operation].fnOrder -
          edgeConditionOperationTypes[b.values.condition.operation].fnOrder
        );
      })
      .find((edge) => {
        return (
          edge.sourceId === nodeId &&
          edge.sourceId !== edge.destinationId &&
          edgeConditionOperationTypes[edge.values.condition.operation].fn(
            executionValue,
            edge.values.condition.value
          )
        );
      });

    if (edge) {
      return edge.destinationId;
    }

    return null;
  };

  const scenarioHeaderClick = () => {
    if (open) {
      setOpen(false);
    } else {
      if (scenarioExecutionState.progress.stepIds.length === 0) {
        const initialNode = Object.values(nodes).find(
          (n) => n.nodeTypeId === "start"
        );
        if (!!initialNode) {
          if (mode === SCENARIO_MODE.ALL) {
            let destinationId = initialNode.id;
            do {
              destinationId = handleStepSubmit(destinationId, true);
            } while (
              !!destinationId &&
              nodes[destinationId].nodeTypeId !== "end"
            );
          }
          if (mode === SCENARIO_MODE.SEQ) {
            handleStepSubmit(initialNode.id, true);
          }
          setTimeout(() => {
            setOpen(true);
          }, 150);
        } else {
          //alert("CHYBA! Není iniciální krok!");
        }
      } else {
        setOpen(true);
      }
    }
  };

  const handleChangeExecutionValues = (nodeId, newValue) => {
    const step = scenarioExecutionState.progress.stepsByIds[nodeId];
    dispatch({
      type: ACTION.PROGRESS_STEP_SET,
      payload: { ...step, value: newValue },
    });
  };

  const handleStepSubmit = (nodeId: string, setNext: boolean = true) => {
    const step = scenarioExecutionState.progress.stepsByIds[nodeId];

    dispatch({
      type: ACTION.PROGRESS_STEP_SET,
      payload: { ...step, submittedAt: new Date().toISOString() },
    });

    if (setNext) {
      const destinationId = findDestinationIdForNodeId(
        nodeId,
        !!step ? step.value : ""
      );

      if (!!destinationId) {
        dispatch({
          type: ACTION.PROGRESS_STEP_ADD,
          payload: nodes[destinationId],
        });

        //pokud destinace je notifikace - vyhodnoť ji a zavolej další destinace
        if (["notification"].includes(nodes[destinationId].nodeTypeId)) {
          handleNotification({
            notification: nodes[destinationId].values,
            scenarioExecutionId: !!scenarioExecution
              ? scenarioExecution.id
              : null,
          });
          return handleStepSubmit(destinationId, setNext);
        }
      } else {
        //alert("CHYBA! Nelze stanovit další krok!");
      }

      return destinationId;
    }
  };

  if (!scenarioExecutionState.progress.hasOwnProperty("stepsByIds")) {
    return <></>;
  }

  return (
    <Box
      sx={{
        background: "#ffffff",
        border: "solid 1px rgba(0, 0, 0, 0.12)",
      }}
    >
      <ScenarioExecutionHeader
        title={title}
        handleClick={() => {
          scenarioHeaderClick();
        }}
        status={getScenarioExecutionStatus(
          scenarioExecutionState,
          !!scenarioExecution?.executionCompletedAt
            ? SCENARIO_EXECUTION_STATUS.COMPLETED
            : SCENARIO_EXECUTION_STATUS.IN_PROGRESS
        )}
        validFrom={scenarioExecution?.validFrom || null}
        validTo={scenarioExecution?.validTo || null}
      />
      <Collapse in={open}>
        {scenarioExecutionState.progress.stepIds
          .filter((nodeId: any) => {
            return !["start"].includes(nodes[nodeId].nodeTypeId);
          })
          .map((nodeId: any) => {
            const node = nodes[nodeId];
            const step = scenarioExecutionState.progress.stepsByIds[nodeId];
            const executionValue = step.value;
            return (
              <ScenarioExecutionStep
                key={nodeId}
                stepStatus={
                  step.submittedAt === null
                    ? STEP_STATUS.ENABLED
                    : STEP_STATUS.DISABLED
                }
                node={node}
                executionValue={executionValue}
                handleUpdate={(newValue) => {
                  handleChangeExecutionValues(nodeId, newValue);
                }}
                handleSubmit={() => {
                  handleStepSubmit(nodeId, mode === SCENARIO_MODE.SEQ);
                }}
              />
            );
          })}
      </Collapse>
    </Box>
  );
};

export default ScenarioExecution;
