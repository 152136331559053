import { entityAdapter } from "../slice/slice";
import { RootState } from "../../../app/store";
import { ENTITY_NAME } from "../config";
import { ENTITY_NAME as SCENARIO_ENTITY_NAME } from "../../scenario/config";
import { createSelector } from "@reduxjs/toolkit";
import { scenarioCodeComparator } from "../../../utils/scenarioCodeComparator.util";

const scenarioSelectors = entityAdapter.getSelectors(
  (state: RootState) => state[SCENARIO_ENTITY_NAME].entities
);

export const scenarioSelectEntities = (state: any) => {
  return scenarioSelectors.selectEntities(state);
};

const selectors = entityAdapter.getSelectors(
  (state: RootState) => state[ENTITY_NAME].entities
);

export const selectSelected = (state: any) => {
  return state[ENTITY_NAME].selected;
};

export const selectSelectedMode = (state: any) => {
  return state[ENTITY_NAME].selectedMode;
};

export const selectAll = (state: any) => {
  return selectors.selectAll(state);
};

export const selectAllSorted = createSelector(
  [selectAll, scenarioSelectEntities],
  (entities, scenarios) => {
    return entities.sort((a, b) => {
      // @ts-ignore
      const aX = scenarios[a.scenarioId];
      // @ts-ignore
      const bX = scenarios[b.scenarioId];

      if (!!aX && !!bX) {
        const r = scenarioCodeComparator(aX, bX);
        if (r !== 0) {
          return r;
        }
        return a.validFrom.localeCompare(b.validFrom);
      }
      return 0;
    });
  }
);
