import { createSlice } from "@reduxjs/toolkit";
import { loadSelectedUnit } from "../utils/selectedUnit.localStorage.util";

export type StateType = {
  dateTime: string | null;
  profile: {
    username: string;
    units: { id: string; storeNumber: string; brandCode: string }[];
    brands: string[];
  } | null;
  selectedUnit: { id: string; storeNumber: string } | null;
};

const initialState: StateType = {
  dateTime: null,
  profile: null,
  selectedUnit: null,
};

export const slice = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setDateTime: (state, action) => {
      state.dateTime = action.payload;
    },
    setSelectedUnit: (state, action) => {
      state.selectedUnit = action.payload;
    },
  },
  extraReducers: (builder) => {},
});
