import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Divider,
} from "@mui/material";
import {
  selectMonthsForReport,
  selectReportConfigurationForm,
  selectReportOpen,
  selectScenariosForReport,
  selectUnitsForReport,
  selectYearsForReport,
} from "../../selectors/report.selector";
import LoadingButton from "@mui/lab/LoadingButton";
import { scenarioExecutionReportDownload } from "../../../../api/api";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { reportSlice } from "../../slices/report.slice";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";

const ReportDialog: React.FC = () => {
  const [loadingInfo, setLoadingInfo] = useState({
    loading: false,
    severity: "info",
    message: "",
  });
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectReportOpen);
  const form = useAppSelector(selectReportConfigurationForm);

  // Replace with your actual selector logic to get lists
  const units = useSelector(selectUnitsForReport);
  const scenarios = useSelector(selectScenariosForReport);
  const months = useSelector(selectMonthsForReport);
  const years = useSelector(selectYearsForReport);

  const handleClose = () => {
    dispatch(reportSlice.actions.setDialogOpen(false));
  };

  const handleDownloadClick = () => {
    setLoadingInfo({ loading: true, severity: "info", message: "" });
    scenarioExecutionReportDownload({
      query: form,
    })
      .then((response) => {
        if (
          "status" in response &&
          "data" in response &&
          response.status === 200
        ) {
          // @ts-ignore
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "Report.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          setLoadingInfo({
            loading: false,
            severity: "info",
            message: "",
          });
        } else {
          setLoadingInfo({
            loading: false,
            severity: "error",
            message: "Report nemůže být stažen.",
          });
        }
      })
      .catch(() => {
        setLoadingInfo({
          loading: false,
          severity: "error",
          message: "Report nemůže být stažen.",
        });
      });
  };

  const loading = loadingInfo.loading;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Reporty a vyplněné formuláře</DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Středisko</InputLabel>
          <Select
            label={"Středisko"}
            value={form.unitId || ""}
            onChange={(e) => {
              dispatch(
                reportSlice.actions.setDialogConfigurationForm({
                  ...form,
                  unitId: e.target.value as string,
                })
              );
            }}
          >
            {units.map((unit: any) => (
              <MenuItem key={unit.id} value={unit.id}>
                {unit.storeNumber}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Formulář</InputLabel>
          <Select
            label={"Formulář"}
            value={form.scenarioId || ""}
            onChange={(e) => {
              dispatch(
                reportSlice.actions.setDialogConfigurationForm({
                  ...form,
                  scenarioId: e.target.value as string,
                })
              );
            }}
          >
            {scenarios.map((scenario: any) => (
              <MenuItem key={scenario.id} value={scenario.id}>
                {scenario.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Rok</InputLabel>
          <Select
            label={"Rok"}
            value={form.year || ""}
            onChange={(e) => {
              dispatch(
                reportSlice.actions.setDialogConfigurationForm({
                  ...form,
                  year: e.target.value as number,
                })
              );
            }}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Měsíc</InputLabel>
          <Select
            label={"Měsíc"}
            value={form.month || ""}
            onChange={(e) => {
              dispatch(
                reportSlice.actions.setDialogConfigurationForm({
                  ...form,
                  month: e.target.value as number,
                })
              );
            }}
          >
            {months.map((month) => (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {loadingInfo.message !== "" && (
          <Alert severity={loadingInfo.severity as any} sx={{ mt: 2 }}>
            {loadingInfo.message}
          </Alert>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary" startIcon={<CloseIcon />}>
          Zavřít
        </Button>
        <LoadingButton
          onClick={handleDownloadClick}
          color="primary"
          variant="contained"
          disabled={loading}
          loading={loading}
          startIcon={<DownloadIcon />}
        >
          Stáhnout
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;
